import Link from 'next/link';
import React from 'react';

import type { LinkItemType } from '../menu/LinkItem';

type LinkItemProps = LinkItemType;
export default function LinkItem({ title, href }: LinkItemProps) {
  return (
    <Link
      href={href}
      className="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 text-dark-light hover:bg-dark/90"
    >
      {title}
    </Link>
  );
}
