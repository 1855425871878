import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React from 'react';

import type { CategorizedLinkItemType } from '..';

type DropdownItemProps = {
  categorizedMenuItem: CategorizedLinkItemType;
};
export default function DropdownItem({
  categorizedMenuItem,
}: DropdownItemProps) {
  return (
    <Disclosure as="div" className="-mx-3">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base leading-7 text-dark-light hover:bg-dark/90">
            {categorizedMenuItem.category}
            <ChevronDownIcon
              className={clsx(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
              aria-hidden="true"
            />
          </Disclosure.Button>
          <Disclosure.Panel className="mt-2 space-y-2">
            {categorizedMenuItem.items.map((item, key) => (
              <Disclosure.Button
                key={key}
                as="a"
                href={item.href}
                className="block rounded-lg py-2 pl-6 pr-3 text-sm leading-7 text-dark-light/80 hover:bg-dark/90"
              >
                {item.title}
              </Disclosure.Button>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
