'use client';

import {
  AcademicCapIcon,
  Bars3Icon,
  ChartBarSquareIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import React, { useState } from 'react';

import { isLoginOrRegisterPath, isOnboardingPath } from '@/middleware/paths';
import {
  ABOUT,
  EDUCATION,
  ENGINEERING,
  HELP_SUPPORT,
  PRICING,
  ROOT,
  SALES,
} from '@/shared/config/route-links.config';
import { useAuth } from '@/shared/hooks/useAuth';

import CanvazaLogo from '../../CanvazaLogo';
import SystemRightSideButtons from '../../internal/appbar/right-side-buttons';
import MenuDrawer from './drawer';
import Menu from './menu';
import type { LinkItemType } from './menu/LinkItem';
import WebRightSideButtons from './right-side-buttons';

export type CategorizedLinkItemType = {
  category: string;
  items: LinkItemType[];
};

export type MenuListType = CategorizedLinkItemType | LinkItemType;

const menuList: MenuListType[] = [
  {
    title: PRICING.name,
    href: PRICING.url,
  },

  {
    category: 'Use cases',
    items: [
      {
        title: EDUCATION.name,
        href: EDUCATION.url,
        icon: (
          <AcademicCapIcon className=" w-6 stroke-dark-light stroke-1 hover:stroke-dark-lighter" />
        ),
      },
      {
        title: ENGINEERING.name,
        href: ENGINEERING.url,
        icon: (
          <CodeBracketIcon className=" w-6 stroke-dark-light stroke-1 hover:stroke-dark-lighter" />
        ),
      },
      {
        title: SALES.name,
        href: SALES.url,
        icon: (
          <ChartBarSquareIcon className=" w-6 stroke-dark-light stroke-1 hover:stroke-dark-lighter" />
        ),
      },
    ],
  },

  { title: ABOUT.name, href: ABOUT.url },

  {
    title: HELP_SUPPORT.name,
    href: HELP_SUPPORT.url,
  },
];
export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const pathname = usePathname();
  const { user: authUser } = useAuth();

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const isAuthPathName = (): boolean => {
    return isLoginOrRegisterPath(pathname) || isOnboardingPath(pathname);
  };

  return (
    <header className="flex w-screen items-center justify-between bg-dark ">
      <nav
        className="fixed inset-x-0 top-0 z-10 flex h-16 w-full items-center justify-center gap-6 bg-dark px-3 sm:px-12 xl:px-0"
        aria-label="Global"
      >
        <div className="flex w-full max-w-7xl items-center justify-between gap-4 lg:gap-6">
          <div
            className={clsx('flex w-full items-center', {
              'gap-6 sm:gap-14': isAuthPathName(),
              'gap-14': !isAuthPathName(),
            })}
          >
            <CanvazaLogo href={ROOT.url} />
            {!isAuthPathName() && <Menu menus={menuList} />}
          </div>

          {authUser ? (
            <SystemRightSideButtons isLanding />
          ) : (
            <WebRightSideButtons />
          )}

          {!isAuthPathName() && (
            <div className="flex lg:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md p-2.5 text-dark-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon
                  className="h-6 w-6 stroke-dark-light"
                  aria-hidden="true"
                />
              </button>
            </div>
          )}

          <MenuDrawer
            menus={menuList}
            open={mobileMenuOpen}
            onClose={closeMobileMenu}
          />
        </div>
      </nav>
    </header>
  );
}
