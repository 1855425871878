/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
export const isObjectNotEmpty = (objectName: object | null | undefined) => {
  return (
    objectName &&
    Object.keys(objectName).length !== 0 &&
    objectName.constructor === Object
  );
};

export const flattenObject = (object: Object) => {
  const result: { [key: string]: any } = {};
  for (const key in object) {
    const value = object[key as keyof typeof object];

    if (typeof value === 'object' && value !== null) {
      Object.assign(result, flattenObject(value));
    } else {
      result[key] = value;
    }
  }
  return result;
};
