import { getCookie, setCookie } from 'cookies-next';
import { usePathname } from 'next/navigation';
import React, { useEffect } from 'react';

import {
  PAYMENT_METHOD_SETTING,
  UPGRADE,
} from '@/shared/config/route-links.config';

type ChildrenArgsType = {
  onToggle: () => void;
  isDark: boolean;
};
type ThemeRenderProps = {
  children: (args: ChildrenArgsType) => React.ReactNode;
};
export default function ThemeRender({ children }: ThemeRenderProps) {
  const pathname = usePathname();
  const [isDark, setIsDark] = React.useState<boolean>(false);
  const pathsWithReload = [PAYMENT_METHOD_SETTING.url, UPGRADE.url];

  useEffect(() => {
    setIsDark(!!getCookie('isDark'));
  }, []);

  React.useEffect(() => {
    const darkInCookie = getCookie('isDark');
    setIsDark(!!darkInCookie);
  }, []);

  const darkModeToggler = () => {
    setIsDark(!isDark);
    setCookie('isDark', !isDark);

    if (isDark) {
      document.body.classList.remove('dark');
    } else {
      document.body.classList.add('dark');
    }
    if (pathsWithReload.some((path) => pathname.startsWith(path))) {
      window.location.reload();
    }
  };

  return (
    <>
      {children({
        isDark,
        onToggle: darkModeToggler,
      })}
    </>
  );
}
