/* eslint-disable no-prototype-builtins */
import { Popover } from '@headlessui/react';
import React from 'react';

import type { CategorizedLinkItemType, MenuListType } from '..';
import DropdownItem from './DropdownItem';
import type { LinkItemType } from './LinkItem';
import LinkItem from './LinkItem';

type MenuProps = {
  menus: MenuListType[];
};
export default function Menu({ menus }: MenuProps) {
  return (
    <Popover.Group className="hidden items-center gap-x-8 lg:flex 2xl:gap-x-12">
      {menus.map((menu, key) => {
        if (typeof menu === 'object' && menu.hasOwnProperty('category')) {
          const categorizedMenuItem = menu as CategorizedLinkItemType;
          return (
            <DropdownItem key={key} categorizedItem={categorizedMenuItem} />
          );
        }
        const linkItemMenu = menu as LinkItemType;
        return (
          <LinkItem
            key={key}
            title={linkItemMenu.title}
            href={linkItemMenu.href}
          />
        );
      })}
    </Popover.Group>
  );
}
