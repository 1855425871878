/* eslint-disable import/no-cycle */
import React, { useState } from 'react';

import { dropdownNotificationLimit } from '@/common/component/layout/internal/appbar/right-side-buttons/NotificationButton';
import type { FilterNotificationBy } from '@/shared/firebase/client/hooks';
import { useGetNotifications } from '@/shared/firebase/client/hooks';
import { useAuth } from '@/shared/hooks/useAuth';

import Notifications from '..';

type DropdownNotificationsProps = {
  onCloseDrawer?: () => void;
};
export default function DropdownNotifications({
  onCloseDrawer,
}: DropdownNotificationsProps) {
  const [filterBy, setFilterBy] = useState<FilterNotificationBy>();

  const { user: authUser } = useAuth();
  const { data: notifications, state: notificationState } = useGetNotifications(
    {
      email: authUser?.email,
      max: dropdownNotificationLimit,
      filterBy,
      key: 'dropdown-notification-list',
    }
  );

  const handleFilterByChange = (filterByKey: FilterNotificationBy) => {
    if (filterByKey === 'all') {
      setFilterBy(undefined);
    } else {
      setFilterBy(filterByKey);
    }
  };
  return (
    <Notifications
      notifications={notifications}
      notificationState={notificationState}
      type="dropdown"
      onFilterBy={handleFilterByChange}
      onCloseDrawer={onCloseDrawer}
    />
  );
}
