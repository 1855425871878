import { Avatar } from '@canvaza/serval-ui';
import clsx from 'clsx';
import React from 'react';

import { CONSTANTS } from '@/shared/config/constant';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';

import FullNameWithAvatarSkeleton from './skeletons/FullNameWithAvatarSkeleton';

type CurrentUserAvatarWithBasicProfileProps = {
  pageType?: 'Sidebar' | 'Page';
};

export default function CurrentUserAvatarWithBasicProfile({
  pageType,
}: CurrentUserAvatarWithBasicProfileProps) {
  const { currentUser: user, state: userState } = useCurrentUser();

  return (
    <>
      {userState?.isLoading && <FullNameWithAvatarSkeleton />}
      {userState?.isSuccess && user && (
        <div className="flex w-full flex-1 items-center gap-2">
          <Avatar
            size="large"
            src={`${CONSTANTS.USERS_AVATARS_BASE_URL}%2F${user.id}%2Favatar?alt=media`}
          />
          <>
            <div className="flex flex-col gap-1">
              <div
                className={clsx('truncate font-bold', {
                  'w-40': pageType === 'Sidebar',
                  'w-auto': pageType === 'Page',
                })}
              >{`${user?.firstName} ${user?.lastName}`}</div>
              <div
                className={clsx('truncate text-sm opacity-75', {
                  'w-40': pageType === 'Sidebar',
                  'w-auto': pageType === 'Page',
                })}
              >
                {user?.email}
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
}
