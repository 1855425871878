import { UserIcon } from '@heroicons/react/24/outline';
import React from 'react';

export default function FullNameWithAvatarSkeleton() {
  return (
    <div className="flex w-full animate-pulse items-center gap-4">
      <div className="flex h-12 w-14 items-center justify-center rounded-full bg-dark-200 dark:bg-dark-500">
        <UserIcon className="w-5 stroke-dark-300  dark:stroke-dark-600" />
      </div>

      <div className="flex w-full flex-col items-center gap-2">
        <div className="h-4 w-full rounded bg-dark-200 px-2 py-1 dark:bg-dark-500"></div>
        <div className="h-2 w-full rounded bg-dark-200 px-2 py-1 dark:bg-dark-500"></div>
      </div>
    </div>
  );
}
