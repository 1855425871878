import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';

export interface LinkItemType {
  title: string;
  href: string;
  description?: string;
  icon?: React.ReactNode;
  noBorder?: boolean;
}

type LinkItemProps = LinkItemType;
export default function LinkItem({ title, href, noBorder }: LinkItemProps) {
  const pathName = usePathname();
  return (
    <Link
      href={href}
      className={clsx('text-xs leading-6 text-dark-lighter lg:text-sm', {
        'pb-0 border-0': noBorder,
        'border-b-2 border-primary': pathName?.startsWith(href),
        'border-b-0 border-primary py-0': !pathName?.startsWith(href),
        'pb-1 border-primary hover:border-b-2 ': !noBorder,
      })}
    >
      {title}
    </Link>
  );
}
