import { Switch, Tooltip } from '@canvaza/serval-ui';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';

import { isAdminPath } from '@/middleware/paths';
import { GENERAL_SETTINGS, HOME } from '@/shared/config/route-links.config';
import useAdminAbility from '@/shared/hooks/useAdminAbility';

export default function SystemModeSwitch() {
  const router = useRouter();
  const pathname = usePathname();
  const { rules } = useAdminAbility();

  const handleRouteToGeneralSetting = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget.checked) {
      router.push(GENERAL_SETTINGS.url);
    } else {
      router.push(HOME.url);
    }
  };

  if (rules.length === 0) return null;

  return (
    <div className="hidden sm:block">
      <div className="mt-[0.65rem] flex items-center justify-center">
        <Tooltip
          content={isAdminPath(pathname) ? 'Admin mode' : 'Workspace mode'}
        >
          <Switch
            size="small"
            checked={isAdminPath(pathname)}
            onChange={(e) => handleRouteToGeneralSetting(e)}
          />
        </Tooltip>
      </div>
    </div>
  );
}
