import Link from 'next/link';
import React from 'react';

import { CanvazaLogoPrime } from '@/shared/utils/svg';

import ThemeRender from './internal/appbar/right-side-buttons/ThemeRender';

type CanvazaLogoProps = {
  href?: string;
};
export default function CanvazaLogo({ href }: CanvazaLogoProps) {
  const getLogo = () => {
    return <CanvazaLogoPrime className="h-8" />;
  };
  return (
    <ThemeRender>
      {() => (
        <>
          {href && <Link href={href}>{getLogo()}</Link>}
          {!href && <>{getLogo()}</>}
        </>
      )}
    </ThemeRender>
  );
}
