import { Avatar, Menu, MenuItem } from '@canvaza/serval-ui';
import {
  ArrowRightOnRectangleIcon,
  BoltIcon,
  Cog8ToothIcon,
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  PuzzlePieceIcon,
  Squares2X2Icon,
  SunIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { MoonIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React from 'react';

import CurrentUserAvatarWithBasicProfile from '@/app/(protected)/(common)/(settings)/setting/personal/CurrentUserAvatarWithBasicProfile';
import { CONSTANTS } from '@/shared/config/constant';
import { FEATURES } from '@/shared/config/feature-flag';
import {
  HOME,
  NOTIFICATION_SETTING,
  PERSONAL_SETTING,
  UPGRADE,
} from '@/shared/config/route-links.config';
import { useAuth } from '@/shared/hooks/useAuth';
import useUserAbility from '@/shared/hooks/useUserAbility';

import InvitationButton from './InvitationButton';
import SystemModeSwitch from './SystemModeSwitch';
import ThemeRender from './ThemeRender';

type UserAccountButtonProps = {
  isLanding?: boolean;
};
export default function UserAccountButton({
  isLanding,
}: UserAccountButtonProps) {
  const userAbility = useUserAbility();
  const { logout, user: authUser } = useAuth();

  const router = useRouter();

  const gotoPersonalSetting = () => router.push(PERSONAL_SETTING.url);
  const gotoNotificationSetting = () => router.push(NOTIFICATION_SETTING.url);
  const handleLogout = async () => {
    await logout();
  };

  const handleRouteToUpgrade = () => {
    router.push(UPGRADE.url);
  };

  const handleRouteToDashboard = () => {
    router.push(HOME.url);
  };
  return (
    <div>
      <Menu
        icon={
          <div className="flex items-center justify-center rounded-md p-1 hover:bg-dark-200/50 dark:hover:bg-dark-400/50">
            <Avatar
              size="small"
              src={`${CONSTANTS.USERS_AVATARS_BASE_URL}%2F${authUser?.uid}%2Favatar?alt=media`}
            />
          </div>
        }
      >
        <div
          className={clsx(
            'flex  w-72 flex-col gap-5 p-2 xs:w-80 sm:w-96 sm:p-4'
          )}
        >
          <div
            className={clsx('w-full items-center justify-between gap-3 p-2 ', {
              flex: isLanding,
              'flex flex-row sm:hidden': !isLanding,
            })}
          >
            <div className="flex items-center justify-start gap-2">
              <div className="flex items-center justify-center rounded-full bg-primary-light/10 p-2">
                <InvitationButton />
              </div>
            </div>
            <SystemModeSwitch />
          </div>
          {isLanding ? (
            <div className="flex w-full flex-col gap-4">
              <MenuItem
                className="gap-2 text-dark-700"
                icon={<Squares2X2Icon className="h-6 w-6" />}
                label="Go To Dashboard"
                onClick={handleRouteToDashboard}
              />
              <div className="justify-start">
                <MenuItem
                  className="text-dark dark:text-dark-light"
                  icon={<ArrowRightOnRectangleIcon className="h-6 w-6" />}
                  label="Sign out"
                  onClick={handleLogout}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="flex w-full items-center justify-between gap-2 px-2">
                <p className="text-base font-semibold md:text-lg">Profile</p>

                {userAbility.can('upgrade', 'subscription') && (
                  <div className="w-2/3 sm:w-1/3">
                    <MenuItem
                      onClick={handleRouteToUpgrade}
                      label="Upgrade"
                      className={clsx(
                        'flex items-center justify-center gap-2 rounded-lg bg-success text-dark-lighter hover:!bg-success/80 focus:!bg-success/80 dark:bg-success-light'
                      )}
                      icon={<BoltIcon className="w-4 py-1.5" />}
                    />
                  </div>
                )}
              </div>

              <div className="flex w-full items-center justify-between p-2">
                <div className="w-4/5">
                  <CurrentUserAvatarWithBasicProfile pageType="Page" />
                </div>
              </div>

              {/* Menu items */}
              <div className="flex flex-col gap-5 px-2">
                <div className="flex flex-col gap-3 border-b border-primary-lighter/40 py-1 pb-3">
                  <MenuItem
                    className="gap-2 text-dark dark:text-dark-light"
                    icon={<UserIcon className="h-6 w-6" />}
                    label="Edit Profile"
                    onClick={gotoPersonalSetting}
                  />
                  <MenuItem
                    className="gap-2 text-dark dark:text-dark-light"
                    icon={<Cog8ToothIcon className="h-6 w-6" />}
                    label="Setting"
                    onClick={gotoNotificationSetting}
                  />
                  <ThemeRender>
                    {({ isDark, onToggle }) => (
                      <MenuItem
                        className="gap-2 text-dark dark:text-dark-light"
                        icon={
                          isDark ? (
                            <SunIcon className="w-6" />
                          ) : (
                            <MoonIcon className="w-6" />
                          )
                        }
                        label={!isDark ? 'Dark Mode' : 'Light Mode'}
                        onClick={onToggle}
                      />
                    )}
                  </ThemeRender>
                </div>
                <div className="flex flex-col gap-5 px-1">
                  {FEATURES.Attributes.downloadPlatformOptions && (
                    <div className="flex flex-col gap-3">
                      <Link
                        href="#"
                        className="flex gap-2 text-sm text-dark dark:text-dark-light"
                      >
                        <DevicePhoneMobileIcon className="h-6 w-6" />
                        Download Mobile App
                      </Link>
                      <Link
                        href="#"
                        className="flex gap-2 text-sm text-dark dark:text-dark-light"
                      >
                        <ComputerDesktopIcon className="h-6 w-6" />
                        Download Desktop App
                      </Link>
                      <Link
                        href="#"
                        className="flex gap-2 text-sm text-dark dark:text-dark-light"
                      >
                        <PuzzlePieceIcon className="h-6 w-6" />
                        Download Chrome Extension
                      </Link>
                    </div>
                  )}
                </div>
                <div className="justify-start">
                  <MenuItem
                    className="text-dark dark:text-dark-light"
                    icon={<ArrowRightOnRectangleIcon className="h-6 w-6" />}
                    label="Sign out"
                    onClick={handleLogout}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Menu>
    </div>
  );
}
