import {
  CHECK_VERIFY_EMAIL,
  INVITATION,
  LOGIN,
  ONBOARD,
  REGISTER,
  ROOT,
  RouterNavs,
} from '@/shared/config/route-links.config';
import { flattenObject } from '@/shared/utils/object';

export const AUTH_PATHS = [...Object.values(flattenObject(RouterNavs.auth))];

export const PUBLIC_PATHS = [
  ...Object.values(flattenObject(RouterNavs.public)),
];

export const PROTECTED_PATHS = [
  ...Object.values(flattenObject(RouterNavs.protected)),
];

export const ADMIN_PATHS = [
  ...Object.values(flattenObject(RouterNavs.protected.admin)),
];

export const USER_PATHS = [
  ...Object.values(flattenObject(RouterNavs.protected.user)),
];

export const SHARED_PATHS = [
  ...Object.values(flattenObject(RouterNavs.shared)),
];

export const SETTINGS_PATHS = [
  ...Object.values(flattenObject(RouterNavs.protected.setting)),
];

export const isAuthPath = (pathname: string): boolean => {
  return AUTH_PATHS.some((path) => pathname.startsWith(path));
};

export const isPublicPath = (pathname: string): boolean => {
  return (
    PUBLIC_PATHS.some((path) => pathname.startsWith(path)) ||
    pathname === ROOT.url
  );
};

export const isProtectedPath = (pathname: string): boolean => {
  return PROTECTED_PATHS.some((path) => pathname.startsWith(path));
};

export const isAdminPath = (pathname: string): boolean => {
  return ADMIN_PATHS.some((path) => pathname.startsWith(path));
};

export const isUserPath = (pathname: string): boolean => {
  return USER_PATHS.some((path) => pathname.startsWith(path));
};

export const isSharedPath = (pathname: string): boolean => {
  return SHARED_PATHS.some((path) => pathname.startsWith(path));
};

export const isVerifyEmailPath = (pathname: string): boolean => {
  return pathname.startsWith(CHECK_VERIFY_EMAIL.url);
};

export const isOnboardingPath = (pathname: string): boolean => {
  return pathname.startsWith(ONBOARD.url);
};

export const isInvitationPath = (pathname: string): boolean => {
  return pathname.startsWith(INVITATION.url);
};

export const isSettingPath = (pathname: string) => {
  return SETTINGS_PATHS.some((path) => pathname.startsWith(path));
};

export const isLoginOrRegisterPath = (pathname: string) => {
  return pathname?.startsWith(LOGIN.url) || pathname?.startsWith(REGISTER.url);
};
