import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import React from 'react';

import { isOnboardingPath } from '@/middleware/paths';
import useAdminAbility from '@/shared/hooks/useAdminAbility';

import InvitationButton from './InvitationButton';
import NotificationButton from './NotificationButton';
import SystemModeSwitch from './SystemModeSwitch';
import UserAccountButton from './UserAccountButton';

type RightSideButtonsProps = {
  isLanding?: boolean;
};

export default function RightSideButtons({ isLanding }: RightSideButtonsProps) {
  const pathname = usePathname();
  const { rules } = useAdminAbility();

  return (
    <div
      className={clsx('grid items-center gap-4', {
        'grid-cols-2 sm:grid-cols-4': rules.length > 0 && !isLanding,
        'grid-cols-2 sm:grid-cols-3': rules.length === 0 && !isLanding,
        'grid-cols-2': isLanding,
      })}
    >
      {!isLanding && (
        <>
          <SystemModeSwitch />
          <div className="hidden sm:block">
            <InvitationButton />
          </div>
        </>
      )}

      {!isOnboardingPath(pathname) && (
        <>
          <NotificationButton isLanding={isLanding} />
          <UserAccountButton isLanding={isLanding} />
        </>
      )}
    </div>
  );
}
