import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import React, { Fragment } from 'react';

import {
  EDUCATION,
  ENGINEERING,
  SALES,
} from '@/shared/config/route-links.config';

import type { CategorizedLinkItemType } from '..';
import LinkItem from './LinkItem';

type ItemProps = {
  categorizedItem: CategorizedLinkItemType;
};

export default function DropdownItem({ categorizedItem }: ItemProps) {
  const pathName = usePathname();
  return (
    <Popover className="relative">
      <Popover.Button
        className={clsx(
          'flex items-center gap-x-1 border-primary pb-1 text-xs leading-6 text-dark-lighter hover:border-b-[1.5px] lg:text-sm ',
          {
            'border-b-2 border-primary':
              pathName?.startsWith(ENGINEERING.url) ||
              pathName?.startsWith(EDUCATION.url) ||
              pathName?.startsWith(SALES.url),
            'border-b-0 border-primary py-0':
              !pathName?.startsWith(ENGINEERING.url) &&
              !pathName?.startsWith(EDUCATION.url) &&
              !pathName?.startsWith(SALES.url),
          }
        )}
      >
        {categorizedItem.category}
        <ChevronDownIcon
          className="h-5 w-5 flex-none text-dark-200"
          aria-hidden="true"
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-xs overflow-hidden rounded-3xl bg-dark ring-1 ring-dark-lighter/25">
          <div className="p-4">
            {categorizedItem.items.map((item, index) => (
              <div
                key={index}
                className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-dark-light/10"
              >
                <div className="flex flex-row items-center gap-3">
                  {item.icon}
                  <LinkItem title={item.title} href={item.href} noBorder />
                </div>
              </div>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
