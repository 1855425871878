import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import React from 'react';

import { isPublicPath } from '@/middleware/paths';

type ButtonWithBadgeProps = {
  maxCount: number;
  count: number;
  children: React.ReactNode;
  onClick: () => void;
};
export default function ButtonWithBadge({
  maxCount,
  count,
  children,
  onClick,
}: ButtonWithBadgeProps) {
  const pathname = usePathname();

  return (
    <button
      onClick={onClick}
      className={clsx(
        'relative flex max-w-max items-center rounded-md p-1 transition duration-300',
        'focus:ring-1 focus:ring-dark-200/60 dark:focus:ring-dark-400/50',
        'hover:bg-dark-200/50 dark:hover:bg-dark-400/50',
        { 'text-dark-lighter': isPublicPath(pathname) }
      )}
    >
      {children}
      {count > 0 && (
        <span
          className={clsx(
            'absolute -top-2 rounded-full bg-error px-1.5 text-xs text-dark-lighter sm:text-sm dark:bg-error-light',
            {
              '-right-3': count >= 10 || count > maxCount,
              '-right-1': count >= 0 && count < 10,
            }
          )}
        >
          {count > maxCount ? `${maxCount}+` : count}
        </span>
      )}
    </button>
  );
}
