import { EnvelopeOpenIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';

import { isPublicPath } from '@/middleware/paths';
import { INVITATION } from '@/shared/config/route-links.config';
import { useGetInvitationNotifications } from '@/shared/firebase/client/hooks';
import { useAuth } from '@/shared/hooks/useAuth';

import ButtonWithBadge from './ButtonWithBadge';

export default function InvitationButton() {
  const pathname = usePathname();
  const router = useRouter();
  const { user: authUser } = useAuth();
  const { data: invitationNotifications } = useGetInvitationNotifications({
    email: authUser?.email,
    key: 'invitation-button',
  });

  const isActiveButton = () => {
    return pathname?.includes(INVITATION.url);
  };

  const handleRoute = () => {
    router.push(INVITATION.url);
  };

  return (
    <ButtonWithBadge
      onClick={handleRoute}
      maxCount={9}
      count={invitationNotifications.length}
    >
      <EnvelopeOpenIcon
        className={clsx('w-[1.45rem] stroke-2', {
          'stroke-dark-500 dark:stroke-dark-200':
            !isPublicPath(pathname) && !isActiveButton(),
          'stroke-dark-500 dark:stroke-dark-light':
            isPublicPath(pathname) && !isActiveButton(),
          'stroke-primary dark:stroke-primary-light': isActiveButton(),
        })}
      />
    </ButtonWithBadge>
  );
}
