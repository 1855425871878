import { Button } from '@canvaza/serval-ui';
import clsx from 'clsx';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';

import { isLoginOrRegisterPath } from '@/middleware/paths';
import { LOGIN, REGISTER } from '@/shared/config/route-links.config';

export default function RightSideButtons() {
  const router = useRouter();
  const pathname = usePathname();

  const isAuthPathName = (): boolean => {
    return isLoginOrRegisterPath(pathname);
  };

  const isLoginPathName = (): boolean => !!pathname?.startsWith(LOGIN.url);

  const isRegisterPathName = (): boolean =>
    !!pathname?.startsWith(REGISTER.url);

  const canIncludeLogin = (): boolean =>
    !isAuthPathName() || isRegisterPathName();

  const canIncludeRegister = (): boolean =>
    !isAuthPathName() || isLoginPathName();

  return (
    <div
      className={clsx('w-2/3 justify-end gap-2 sm:w-1/2 md:w-1/3', {
        'hidden lg:flex': !isAuthPathName(),
        flex: isAuthPathName(),
      })}
    >
      {canIncludeLogin() && (
        <Button
          size="large"
          onClick={() => router.push(LOGIN.url)}
          color="dark"
        >
          <span className="whitespace-nowrap text-dark-light">Sign in</span>
        </Button>
      )}

      {canIncludeRegister() && (
        <Button
          size="large"
          variant="contained"
          className="rounded bg-primary px-3 py-2 sm:px-6"
          onClick={() => router.push(REGISTER.url)}
        >
          Sign up
        </Button>
      )}
    </div>
  );
}
