/* eslint-disable import/no-cycle */
import { Drawer } from '@canvaza/serval-ui';
import { BellIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import DropdownNotifications from '@/app/(protected)/(common)/notifications/dropdown-notifications';
import { isPublicPath } from '@/middleware/paths';
import { NOTIFICATIONS } from '@/shared/config/route-links.config';
import { useGetNotifications } from '@/shared/firebase/client/hooks';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';

import ButtonWithBadge from './ButtonWithBadge';

export const dropdownNotificationLimit = 9;

type NotificationButtonProps = {
  isLanding?: boolean;
};
export default function NotificationButton({
  isLanding,
}: NotificationButtonProps) {
  const [notificationCount, setNotificationCount] = useState(0);
  const route = useRouter();
  const [showNotificationDrawer, setShowNotificationDrawer] =
    React.useState(false);
  const pathname = usePathname();

  const { currentUser, state: currentUserState } = useCurrentUser();

  const { data: notifications, state: notificationState } = useGetNotifications(
    {
      email: currentUser?.email,
      key: 'notifications-count',
    }
  );

  const handleCloseDrawer = () => setShowNotificationDrawer(false);
  const handleShowNotification = () => {
    if (isLanding) {
      route.push(NOTIFICATIONS.url);
      setShowNotificationDrawer(false);
    } else {
      setShowNotificationDrawer(true);
    }
  };
  useEffect(() => {
    const updateNotificationsLength = notifications.filter(
      (val) =>
        (val.workspaceId === currentUser?.defaultWorkspaceId ||
          val.workspaceId === null) &&
        !val.readStatus
    ).length;

    if (currentUserState?.isSuccess && notificationState?.isSuccess) {
      setNotificationCount(updateNotificationsLength);
    }
  }, [
    notificationState?.isSuccess,
    currentUserState?.isSuccess,
    notifications,
    currentUser,
  ]);

  return (
    <div>
      <ButtonWithBadge
        onClick={handleShowNotification}
        maxCount={dropdownNotificationLimit}
        count={notificationCount}
      >
        <BellIcon
          className={clsx('w-6 stroke-2', {
            'stroke-dark-500 dark:stroke-dark-200': !isPublicPath(pathname),
            'stroke-dark-lighter': isPublicPath(pathname),
          })}
        />
      </ButtonWithBadge>

      <Drawer
        size="small"
        open={showNotificationDrawer}
        onClose={handleCloseDrawer}
        className="bg-dark-lighter dark:bg-dark-600"
      >
        <DropdownNotifications onCloseDrawer={handleCloseDrawer} />
      </Drawer>
    </div>
  );
}
