/* eslint-disable no-prototype-builtins */
import { Button } from '@canvaza/serval-ui';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useRouter } from 'next/navigation';
import React from 'react';

import { HOME, LOGIN, ROOT } from '@/shared/config/route-links.config';
import { useAuth } from '@/shared/hooks/useAuth';

import CanvazaLogo from '../../../CanvazaLogo';
import type { CategorizedLinkItemType, MenuListType } from '..';
import type { LinkItemType } from '../menu/LinkItem';
import DropdownItem from './DropdownItem';
import LinkItem from './LinkItem';

type MenuDrawerProps = {
  menus: MenuListType[];
  open: boolean;
  onClose: () => void;
};
export default function MenuDrawer({
  menus,
  open: openDrawer,
  onClose,
}: MenuDrawerProps) {
  const router = useRouter();
  const { user: authUser } = useAuth();
  return (
    <Dialog as="div" className="lg:hidden" open={openDrawer} onClose={onClose}>
      <div className="fixed inset-0 z-10" />
      <Dialog.Panel className="fixed inset-y-0 right-0 z-10 flex w-full flex-col justify-between overflow-y-auto bg-dark sm:max-w-sm sm:ring-1 sm:ring-dark/10">
        <div className="p-6">
          <div className="flex w-full items-center justify-between">
            <div className="w-1/2">
              <CanvazaLogo href={authUser ? HOME.url : ROOT.url} />
            </div>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-dark-700"
              onClick={onClose}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon
                className="h-6 w-6 stroke-dark-light"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-dark-500/10">
              <div className="space-y-2 py-6">
                {menus.map((menu, key) => {
                  if (
                    typeof menu === 'object' &&
                    menu.hasOwnProperty('category')
                  ) {
                    const categorizedMenuItem = menu as CategorizedLinkItemType;
                    return (
                      <DropdownItem
                        key={key}
                        categorizedMenuItem={categorizedMenuItem}
                      />
                    );
                  }

                  const linkItemMenu = menu as LinkItemType;
                  return (
                    <LinkItem
                      key={key}
                      title={linkItemMenu.title}
                      href={linkItemMenu.href}
                    />
                  );
                })}
              </div>
              <div className="flex flex-col gap-4 py-6">
                <Button size="small" onClick={() => router.push(LOGIN.url)}>
                  Sign in
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  className="rounded bg-primary px-6 py-2"
                  onClick={() => router.push(LOGIN.url)}
                >
                  Sign up
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
